@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) '../prerequisites/assets/stylesheets/variables.less';
@import (reference) "../prerequisites/assets/stylesheets/mixins/mixins.less";

html {
  height: 100%;
  overflow-y:hidden;
  overflow-x:hidden!important;
}

body, #main {
  height: 100%;
}

/*-- SPINNER --*/
#spinner,
.spinner {
  .border-radius(@ax-border-radius-l);
  position: fixed;
  left: 50%;
  top: 40%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  background-color: @black;
  z-index: 9999;
}

#spinner .img,
.spinner .img {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 32px;
  height: 32px;
  background-image: url('@common/assets/images/loading_new_black.gif');
  .backface-cull-fix();
  background-position: center center;
  background-repeat: no-repeat;
  background-color: @black;

  .ax-media-query-retina({
      background-image: url('@common/assets/images/loading_new_black@2x.gif');
      background-size: 32px;
  })
}

[dir=rtl] {
  .fr-tooltip {
    width: 'fit-content';
    right: auto !important;
  }
}

.fr-popup.fr-active {
  z-index: 1301 !important;
}
